/* Base styles */
:root {
  /* Light theme */
  --primary-color: #2563eb;
  --secondary-color: #1e40af;
  --background-color: #f3f4f6;
  --border-color: #e5e7eb;
  --text-primary: #1f2937;
  --text-secondary: #6b7280;
  --success-color: #059669;
  --customer-message-bg: #f0f7ff;
  --agent-message-bg: #ffffff;
  --card-bg: #ffffff;
  --hover-bg: rgba(37, 99, 235, 0.1);
  --analysis-bg: #ffffff;
  --score-item-bg: #ffffff;
  --overall-score-bg: #f8fafc;
}

[data-theme='dark'] {
  --primary-color: #3b82f6;
  --secondary-color: #60a5fa;
  --background-color: #111827;
  --border-color: #374151;
  --text-primary: #f3f4f6;
  --text-secondary: #9ca3af;
  --success-color: #10b981;
  --customer-message-bg: #1e3a8a;
  --agent-message-bg: #1f2937;
  --card-bg: #1f2937;
  --hover-bg: rgba(59, 130, 246, 0.2);
  --analysis-bg: #1e293b;
  --score-item-bg: #1e293b;
  --overall-score-bg: #1e293b;
  --chat-list-bg: #1f2937;
  --message-border: #374151;
}

body {
  background-color: var(--background-color);
  color: var(--text-primary);
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, sans-serif;
  margin: 0;
  padding: 0;
}

/* App Layout */
.app-container {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.main-content {
  flex: 1;
  padding-top: 64px; /* Height of header */
}

/* Header Styles */
.main-header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 64px;
  background: var(--card-bg);
  border-bottom: 1px solid var(--border-color);
  z-index: 1000;
  box-shadow: 0 1px 3px rgba(0,0,0,0.1);
}

.header-content {
  max-width: 1800px;
  margin: 0 auto;
  padding: 0 24px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.logo {
  font-size: 1.25rem;
  font-weight: 600;
}

.logo a {
  color: var(--primary-color);
  text-decoration: none;
}

.main-nav {
  display: flex;
  gap: 24px;
  align-items: center;
}

.nav-item {
  display: flex;
  align-items: center;
  gap: 8px;
  color: var(--text-primary);
  text-decoration: none;
  padding: 8px 16px;
  border-radius: 8px;
  transition: all 0.2s ease;
  font-size: 0.875rem;
  font-weight: 500;
}

.nav-item .icon {
  font-size: 1.1em;
}

.nav-item:hover {
  background: var(--hover-bg);
  color: var(--primary-color);
  transform: translateY(-1px);
}

.nav-item.active {
  background: var(--primary-color);
  color: white;
}

.nav-item.analytics {
  background: var(--hover-bg);
  border: 1px solid var(--border-color);
}

.nav-item.analytics:hover {
  border-color: var(--primary-color);
  background: var(--primary-color);
  color: white;
}

/* Adjust main content to account for fixed header */
.main-content {
  padding-top: 64px;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .header-content {
    padding: 0 16px;
  }
  
  .nav-item {
    padding: 6px 12px;
    font-size: 0.75rem;
  }
  
  .logo {
    font-size: 1rem;
  }
}

/* Layout */
.dashboard {
  display: grid;
  grid-template-columns: 350px 1fr;
  gap: 24px;
  padding: 24px;
  height: calc(100vh - 88px); /* Adjust for header */
  max-width: 1800px;
  margin: 0 auto;
}

/* Filters */
.filters-container {
  display: flex;
  gap: 12px;
  margin-bottom: 16px;
  align-items: center;
  padding: 12px;
  background-color: var(--card-bg);
  border-radius: 8px;
  border: 1px solid var(--border-color);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  flex-wrap: wrap;
}

.search-filter, .points-filter, .agent-filter {
  flex: 1;
  min-width: 150px;
}

.ticket-search, .points-input, .agent-search, .points-dropdown {
  width: 100%;
  padding: 8px 12px;
  border: 1px solid var(--border-color);
  border-radius: 4px;
  font-size: 14px;
  background-color: var(--background-color);
  color: var(--text-primary);
  transition: border-color 0.2s;
}

.ticket-search:focus, .points-input:focus, .agent-search:focus, .points-dropdown:focus {
  border-color: var(--primary-color);
  outline: none;
}

.points-dropdown {
  cursor: pointer;
  appearance: none;
  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 8px center;
  background-size: 16px;
  padding-right: 32px;
}

.clear-filters {
  background-color: transparent;
  border: 1px solid var(--border-color);
  color: var(--text-secondary);
  padding: 8px 12px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  transition: all 0.2s;
}

.clear-filters:hover {
  background-color: var(--hover-bg);
  color: var(--primary-color);
}

@media (max-width: 768px) {
  .filters-container {
    flex-direction: column;
    align-items: stretch;
  }
  
  .search-filter, .points-filter, .agent-filter {
    width: 100%;
  }
  
  .clear-filters {
    margin-top: 8px;
  }
}

/* Chat List */
.chat-list-container {
  background: var(--card-bg);
  border-radius: 12px;
  box-shadow: 0 1px 3px rgba(0,0,0,0.1);
  height: calc(100vh - 112px); /* Adjust for header and padding */
  overflow: hidden;
  display: flex;
  flex-direction: column;
  border: 1px solid var(--border-color);
}

.chat-list {
  padding: 16px;
  overflow-y: auto;
  flex-grow: 1;
}

.chat-list h2 {
  font-size: 1.25rem;
  font-weight: 600;
  margin: 0 0 16px 0;
  padding-bottom: 12px;
  border-bottom: 1px solid var(--border-color);
  color: var(--text-primary);
}

.chat-item {
  background: var(--card-bg);
  padding: 16px;
  margin-bottom: 12px;
  border-radius: 8px;
  border: 1px solid var(--border-color);
  cursor: pointer;
  transition: all 0.2s ease;
  color: var(--text-primary);
  position: relative;
}

.chat-item:hover {
  background: var(--hover-bg);
  transform: translateY(-1px);
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
}

.chat-item.active {
  background: var(--hover-bg);
  border-color: var(--primary-color);
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.chat-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
}

.chat-header .ticket-id {
  font-weight: 600;
  color: var(--primary-color);
}

.chat-header .date {
  font-size: 0.875rem;
  color: var(--text-secondary);
}

.chat-labels {
  display: flex;
  gap: 6px;
  flex-wrap: wrap;
}

.label {
  padding: 4px 8px;
  border-radius: 12px;
  font-size: 0.8rem;
}

.chat-title {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.chat-title .ticket-id {
  font-weight: 600;
  color: var(--primary-color);
  font-size: 0.9rem;
}

.chat-title .agent-name {
  color: var(--text-primary);
  font-weight: 500;
  font-size: 1rem;
}

.chat-summary {
  margin-top: 8px;
  font-size: 0.8rem;
  color: var(--text-secondary);
}

/* Chat Detail */
.chat-detail-container {
  background: var(--card-bg);
  border-radius: 12px;
  box-shadow: 0 1px 3px rgba(0,0,0,0.1);
  height: calc(100vh - 112px); /* Adjust for header and padding */
  overflow: hidden;
  display: flex;
  flex-direction: column;
  border: 1px solid var(--border-color);
}

.chat-detail {
  padding: 24px;
  overflow-y: auto;
  flex-grow: 1;
  background: var(--card-bg);
  color: var(--text-primary);
}

.chat-detail-header {
  padding: 20px;
  border-bottom: 1px solid var(--border-color);
  margin-bottom: 24px;
}

.header-main {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 24px;
}

.header-left {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.header-left h2 {
  margin: 0;
  font-size: 1.2rem;
  color: var(--text-primary);
}

.chat-meta {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 8px 0;
}

.score-badge {
  background: var(--primary-color);
  color: white;
  padding: 4px 8px;
  border-radius: 12px;
  font-size: 0.8rem;
  font-weight: 600;
  display: flex;
  align-items: center;
  gap: 4px;
}

/* Add color variations based on score */
.score-badge[title="Overall Performance"] {
  background: linear-gradient(to right, var(--primary-color), var(--secondary-color));
}

/* Optional: Add different colors based on score ranges */
.chat-item[data-score="high"] .score-badge {
  background: linear-gradient(to right, #059669, #047857);
}

.chat-item[data-score="medium"] .score-badge {
  background: linear-gradient(to right, #d97706, #b45309);
}

.chat-item[data-score="low"] .score-badge {
  background: linear-gradient(to right, #dc2626, #b91c1c);
}

.agents-summary {
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: flex-end;
}

.agents-list {
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
  justify-content: flex-end;
}

.agent-pill {
  display: flex;
  align-items: center;
  gap: 6px;
  padding: 4px 8px;
  background: var(--card-bg);
  border: 1px solid var(--border-color);
  border-radius: 16px;
  font-size: 0.85rem;
}

.agent-pill.primary {
  border-color: var(--primary-color);
  background: linear-gradient(
    to right,
    var(--card-bg),
    var(--card-bg) 98%,
    var(--primary-color)
  );
}

.agent-initial {
  width: 20px;
  height: 20px;
  background: var(--primary-color);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 0.75rem;
  font-weight: 600;
}

.agent-name {
  color: var(--text-primary);
  font-weight: 500;
}

.message-count {
  color: var(--text-secondary);
  font-size: 0.8rem;
}

.total-messages {
  color: var(--text-secondary);
  font-size: 0.85rem;
}

.chat-transcript {
  margin: 24px 0;
}

.message {
  display: flex;
  flex-direction: column;
  max-width: 80%;
  margin: 16px 0;
  animation: fadeIn 0.3s ease;
}

.message.customer {
  margin-right: auto;
}

.message.agent {
  margin-left: auto;
}

.message-content {
  padding: 12px 16px;
  border-radius: 12px;
  position: relative;
  line-height: 1.5;
  color: var(--text-primary);
}

.message.customer .message-content {
  background: var(--customer-message-bg);
  border: 1px solid var(--message-border);
  border-bottom-left-radius: 4px;
  color: var(--text-primary);
}

.message.agent .message-content {
  background: var(--agent-message-bg);
  border: 1px solid var(--message-border);
  border-bottom-right-radius: 4px;
  color: var(--text-primary);
}

.message-header {
  display: flex;
  gap: 8px;
  align-items: center;
  margin-bottom: 4px;
}

.sender {
  font-weight: 500;
  font-size: 0.875rem;
  color: var(--text-primary);
}

.time {
  color: var(--text-secondary);
  font-size: 0.75rem;
}

/* Analysis Section */
.analysis-section {
  margin-top: 16px;
  background: var(--analysis-bg);
  border-radius: 12px;
  padding: 24px;
  border: 1px solid var(--border-color);
  color: var(--text-primary);
}

.analysis-section h3 {
  font-size: 1.25rem;
  font-weight: 600;
  margin: 0 0 20px 0;
  color: var(--text-primary);
}

.scores-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
  margin-bottom: 32px;
}

.score-item {
  background: var(--score-item-bg);
  padding: 20px;
  border-radius: 8px;
  border: 1px solid var(--border-color);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
}

.score-item label {
  display: block;
  margin-bottom: 12px;
  font-weight: 500;
  color: var(--text-primary);
}

.score-bar-container {
  display: flex;
  align-items: center;
  gap: 12px;
}

.score-bar {
  flex-grow: 1;
  height: 8px;
  background: var(--border-color);
  border-radius: 4px;
  overflow: hidden;
}

.score-fill {
  height: 100%;
  background: var(--primary-color);
  border-radius: 4px;
  transition: width 0.3s ease;
}

.score-value {
  font-weight: 600;
  color: var(--text-primary);
  min-width: 40px;
  text-align: right;
}

.analysis-details {
  margin-top: 32px;
}

.analysis-feedback {
  background: var(--score-item-bg);
  padding: 24px;
  border-color: var(--border-color);
  border-radius: 8px;
}

.analysis-columns {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 24px;
}

.key-points, .suggestions {
  background: var(--score-item-bg);
  padding: 24px;
  border-color: var(--border-color);
  border-radius: 8px;
}

.analysis-details h4 {
  font-size: 1rem;
  font-weight: 600;
  margin: 0 0 16px 0;
  color: var(--text-primary);
}

.analysis-details ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.analysis-details li {
  display: flex;
  gap: 8px;
  margin-bottom: 12px;
  color: var(--text-secondary);
  line-height: 1.5;
}

.bullet {
  color: var(--success-color);
  font-weight: bold;
}

.suggestions .bullet {
  color: var(--primary-color);
}

.analysis-details p {
  margin: 0;
  color: var(--text-secondary);
  line-height: 1.6;
}

/* Animations */
@keyframes fadeIn {
  from { opacity: 0; transform: translateY(10px); }
  to { opacity: 1; transform: translateY(0); }
}

/* Pagination */
.pagination {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-top: 20px;
}

.pagination button {
  padding: 8px 16px;
  border: none;
  background: #007bff;
  color: white;
  border-radius: 4px;
  cursor: pointer;
}

.pagination button:disabled {
  background: #cccccc;
  cursor: not-allowed;
}

/* Add these to your existing CSS */

.loading {
  padding: 20px;
  text-align: center;
  color: #666;
}

.no-chats {
  padding: 20px;
  text-align: center;
  color: var(--text-secondary);
  background: var(--card-bg);
  border-radius: 8px;
}

.error-message {
  background: rgba(211, 47, 47, 0.1);
  color: #ef5350;
  padding: 15px;
  margin: 10px;
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.error-message button {
  background: #d32f2f;
  color: white;
  border: none;
  padding: 5px 10px;
  border-radius: 4px;
  cursor: pointer;
}

.error-message button:hover {
  background: #b71c1c;
}

/* Add to your existing CSS */

.analysis-feedback, .analysis-points, .analysis-suggestions {
  margin-top: 20px;
  padding: 16px;
  background: var(--score-item-bg);
  border-radius: 8px;
  border: 1px solid var(--border-color);
}

.analysis-feedback h4, .analysis-points h4, .analysis-suggestions h4 {
  margin: 0 0 12px 0;
  color: var(--text-primary);
  font-size: 1rem;
}

.analysis-points ul, .analysis-suggestions ul {
  margin: 0;
  padding-left: 20px;
}

.analysis-points li, .analysis-suggestions li {
  margin: 8px 0;
  color: var(--text-secondary);
  line-height: 1.5;
}

.analysis-feedback p {
  margin: 0;
  color: var(--text-secondary);
  line-height: 1.6;
}

/* Analysis Section Updates */
.score-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
}

.score-header label {
  margin-bottom: 0;
  font-weight: 600;
  color: var(--text-primary);
}

.raw-score {
  color: var(--primary-color);
  font-weight: 600;
  font-size: 0.875rem;
}

.chat-transcript-section {
  margin-top: 32px;
  background: var(--card-bg);
  border-radius: 12px;
  padding: 24px;
  border: 1px solid var(--border-color);
}

.chat-transcript-section h3 {
  font-size: 1.25rem;
  font-weight: 600;
  margin: 0 0 20px 0;
  color: var(--text-primary);
}

/* Add theme toggle button styles */
.theme-toggle {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 1001; /* Above header */
}

.theme-toggle:hover {
  transform: scale(1.1);
  background: var(--hover-bg);
}

.overall-score {
  grid-column: 1 / -1;
  background: var(--overall-score-bg);
  border: 1px solid var(--border-color);
}

/* Add smooth transitions for theme switching */
body * {
  transition: background-color 0.3s ease, color 0.3s ease, border-color 0.3s ease, box-shadow 0.3s ease;
}

/* Update/add these CSS rules */

/* Ensure all text inputs and buttons respect theme */
input, button {
  background: var(--card-bg);
  color: var(--text-primary);
  border-color: var(--border-color);
}

/* Add transition for smoother theme switching */
* {
  transition: background-color 0.3s ease,
              color 0.3s ease,
              border-color 0.3s ease,
              box-shadow 0.3s ease;
}

/* Add box shadow for better depth in dark mode */
[data-theme='dark'] .chat-detail-container {
  box-shadow: 0 1px 3px rgba(0,0,0,0.2);
}

[data-theme='dark'] .chat-detail {
  background: var(--card-bg);
}

/* Ensure header elements have proper contrast */
[data-theme='dark'] .chat-detail-header {
  border-color: var(--border-color);
}

.message-stats {
  color: var(--text-secondary);
}

.agent-details {
  margin-top: 16px;
  padding: 16px;
  background: var(--card-bg);
  border-radius: 8px;
  border: 1px solid var(--border-color);
}

.agents-list {
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-top: 12px;
}

.agent-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px;
  background: var(--score-item-bg);
  border-radius: 6px;
  border: 1px solid var(--border-color);
}

.agent-item.primary {
  border-color: var(--primary-color);
}

.primary-badge {
  background: var(--primary-color);
  color: white;
  padding: 2px 8px;
  border-radius: 4px;
  font-size: 0.75rem;
  margin-left: 8px;
}

.agent-info {
  display: flex;
  align-items: center;
}

.agent-stats {
  display: flex;
  gap: 16px;
  color: var(--text-secondary);
  font-size: 0.875rem;
}

/* Agent Section Styles */
.agents-section {
  display: none;
}

.agents-section h3 {
  margin-bottom: 16px;
  color: var(--text-primary);
  font-size: 1.2rem;
}

.agent-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
  padding-bottom: 8px;
  border-bottom: 1px solid var(--border-color);
}

.primary-badge {
  background: var(--primary-color);
  color: white;
  padding: 4px 12px;
  border-radius: 16px;
  font-size: 0.75rem;
  font-weight: 500;
  letter-spacing: 0.5px;
}

.stat-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 6px 0;
  font-size: 0.9rem;
}

.stat-label {
  color: var(--text-secondary);
}

.stat-value {
  color: var(--text-primary);
  font-weight: 600;
  background: var(--score-item-bg);
  padding: 2px 8px;
  border-radius: 12px;
  font-size: 0.85rem;
}

/* Add to your existing CSS */

.agents-list {
  padding: 24px;
  max-width: 1800px;
  margin: 0 auto;
}

.agents-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 24px;
  margin-top: 24px;
}

.agent-card {
  background: var(--card-bg);
  border: 1px solid var(--border-color);
  border-radius: 8px;
  padding: 16px;
  text-decoration: none;
  color: var(--text-primary);
  transition: transform 0.2s, box-shadow 0.2s;
}

.agent-card:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.agent-info h2 {
  margin: 0 0 8px 0;
  font-size: 1.25rem;
}

.agent-info p {
  color: var(--text-secondary);
  margin: 0;
}

.agent-stats {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 16px;
  margin-top: 16px;
  padding-top: 16px;
  border-top: 1px solid var(--border-color);
}

.stat {
  display: flex;
  flex-direction: column;
}

.stat label {
  font-size: 0.875rem;
  color: var(--text-secondary);
}

.stat span {
  font-size: 1.25rem;
  font-weight: 600;
  color: var(--primary-color);
}

.dashboard-nav {
  margin-bottom: 24px;
}

.nav-link {
  color: var(--primary-color);
  text-decoration: none;
  padding: 8px 16px;
  border-radius: 4px;
  background: var(--hover-bg);
}

.nav-link:hover {
  background: var(--primary-color);
  color: white;
}

/* Add these new styles at the end of your existing CSS */

.chat-quote {
  margin: 8px 0;
  padding: 8px 16px;
  border-left: 3px solid var(--primary-color);
  background: var(--hover-bg);
  border-radius: 4px;
  font-style: italic;
  color: var(--text-secondary);
}

.suggested-responses {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.suggested-response {
  background: var(--card-bg);
  border: 1px solid var(--border-color);
  border-radius: 8px;
  padding: 16px;
}

.customer-query {
  margin-bottom: 8px;
  color: var(--text-secondary);
}

.better-response {
  color: var(--text-primary);
}

.suggestion-item {
  margin-bottom: 1.5rem;
  background: var(--card-bg);
  border: 1px solid var(--border-color);
  border-radius: 8px;
  padding: 1rem;
}

.suggestion-content {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
}

.suggestion-text {
  display: flex;
  gap: 0.5rem;
  align-items: flex-start;
  color: var(--text-primary);
}

.suggestion-text .icon {
  color: var(--primary-color);
  flex-shrink: 0;
}

.suggestion-example {
  margin-left: 1.5rem;
  padding: 0.75rem;
  background: var(--hover-bg);
  border-left: 3px solid var(--primary-color);
  border-radius: 4px;
}

.example-label {
  color: var(--text-secondary);
  font-weight: 500;
  margin-right: 0.5rem;
}

.example-text {
  font-style: italic;
  color: var(--text-primary);
}

/* Suggested responses styles */
.suggested-response {
  background: var(--card-bg);
  border: 1px solid var(--border-color);
  border-radius: 8px;
  padding: 1rem;
  margin-bottom: 1rem;
}

.response-type {
  color: var(--primary-color);
  font-weight: 500;
  margin-bottom: 0.5rem;
}

.response-content {
  color: var(--text-primary);
  padding: 0.75rem;
  background: var(--hover-bg);
  border-radius: 4px;
}

/* Auth Pages */
.auth-page {
  padding-top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background: linear-gradient(135deg, var(--background-color) 0%, var(--hover-bg) 100%);
}

.login-container {
  width: 100%;
  max-width: 400px;
  padding: 20px;
}

.login-card {
  background: var(--card-bg);
  border-radius: 12px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  padding: 32px;
  text-align: center;
  border: 1px solid var(--border-color);
}

.login-header h1 {
  color: var(--primary-color);
  margin-bottom: 8px;
  font-size: 1.75rem;
}

.login-header p {
  color: var(--text-secondary);
  margin-bottom: 24px;
}

.google-login-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 12px 16px;
  background: var(--card-bg);
  border: 1px solid var(--border-color);
  border-radius: 8px;
  font-size: 1rem;
  font-weight: 500;
  color: var(--text-primary);
  cursor: pointer;
  transition: all 0.2s ease;
  margin: 16px 0;
}

.google-login-button:hover {
  background: var(--hover-bg);
  transform: translateY(-1px);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.google-login-button svg {
  margin-right: 12px;
}

.login-footer {
  margin-top: 24px;
  color: var(--text-secondary);
  font-size: 0.875rem;
}

.error-message {
  background: #fee2e2;
  color: #b91c1c;
  padding: 12px;
  border-radius: 8px;
  margin-bottom: 16px;
  text-align: left;
  border: 1px solid #f87171;
}

.loading-screen {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
}

.auth-callback-loading,
.auth-callback-error {
  text-align: center;
  padding: 48px;
}

.loading-spinner {
  display: inline-block;
  width: 40px;
  height: 40px;
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  border-top-color: var(--primary-color);
  animation: spin 1s ease-in-out infinite;
  margin: 24px auto;
}

@keyframes spin {
  to { transform: rotate(360deg); }
}

/* User menu in header */
.user-menu {
  display: flex;
  align-items: center;
  gap: 16px;
}

.user-info {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.user-email {
  font-size: 0.875rem;
  color: var(--text-secondary);
}

.sign-out-button {
  background: transparent;
  border: 1px solid var(--border-color);
  color: var(--text-primary);
  padding: 6px 12px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 0.875rem;
  transition: all 0.2s ease;
}

.sign-out-button:hover {
  background: var(--hover-bg);
  color: var(--primary-color);
}

/* Admin Page */
.admin-page {
  max-width: 1000px;
  margin: 0 auto;
  padding: 24px;
}

.admin-page h1 {
  margin-bottom: 8px;
  color: var(--text-primary);
}

.admin-page p {
  color: var(--text-secondary);
  margin-bottom: 24px;
}

.add-email-section,
.emails-list-section {
  background-color: var(--card-bg);
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 24px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  border: 1px solid var(--border-color);
}

.add-email-section h2,
.emails-list-section h2 {
  margin-top: 0;
  margin-bottom: 16px;
  font-size: 18px;
  color: var(--text-primary);
}

.add-email-section button {
  background-color: var(--primary-color);
  color: white;
  border: none;
  border-radius: 4px;
  padding: 8px 16px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.2s;
}

.add-email-section button:hover {
  background-color: var(--secondary-color);
}

.add-email-section button:disabled {
  background-color: var(--border-color);
  cursor: not-allowed;
}

.form-group {
  margin-bottom: 16px;
}

.form-group label {
  display: block;
  margin-bottom: 8px;
  color: var(--text-primary);
}

.form-group input {
  width: 100%;
  padding: 8px 12px;
  border: 1px solid var(--border-color);
  border-radius: 4px;
  font-size: 14px;
  background-color: var(--background-color);
  color: var(--text-primary);
}

.emails-table {
  width: 100%;
  border-collapse: collapse;
}

.emails-table th,
.emails-table td {
  padding: 12px;
  text-align: left;
  border-bottom: 1px solid var(--border-color);
}

.emails-table th {
  font-weight: 600;
  color: var(--text-primary);
  background-color: var(--background-color);
}

.emails-table tr:hover {
  background-color: var(--hover-bg);
}

.delete-button {
  background-color: #ef4444;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 6px 12px;
  cursor: pointer;
  font-size: 12px;
  transition: background-color 0.2s;
}

.delete-button:hover {
  background-color: #dc2626;
}

.delete-button:disabled {
  background-color: #f87171;
  cursor: not-allowed;
}

.success-message {
  background-color: #10b981;
  color: white;
  padding: 12px;
  border-radius: 4px;
  margin-bottom: 16px;
}

.no-emails {
  padding: 16px;
  text-align: center;
  color: var(--text-secondary);
  background-color: var(--background-color);
  border-radius: 4px;
}

.link-button {
  background: none;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  text-decoration: none;
  color: inherit;
}

.link-button:focus {
  outline: none;
} 